<template>
  <el-dialog title="绑定企业" :visible="boudnEnterpriseVisible" width="850px" append-to-body @close="close">
    <div class="content">
      <Table
        :loading="loading"
        :table-cols="tableCols"
        :table-data="tableData"
        :pagination="pagination"
        @sizeChange="sizeChange"
        @pageChange="pageChange"
      >
        <!-- <template v-slot="{ col, row }">
          <div v-if="col.prop === 'list'">
            <div v-for="(item,index) in row.list" :key="item.id">
              {{ roleCodeTypeMap[item.roleCode]||'-' }}<span v-if="index<row.list.length-1">+</span>
            </div>
          </div>
        </template> -->
      </Table>
    </div>
  </el-dialog>
</template>

<script>
import { companyUserRellist } from '@/api/systemUser';
// import { roleCodeTypeMap } from '@/config/constant';
export default {
  props: {
    boudnEnterpriseVisible: {
      type: Boolean,
      default() {
        return false;
      },
    },
    boudnEnterpriseId: {
      type: [String, Number],
      default() {
        return false;
      },
    },
  },
  data() {
    return {
      loading: false,
      // roleCodeTypeMap,
      tableCols: [
        { label: '企业类型', prop: 'cpyType', type: 'transition', rulesObj: { 1: '货主', 2: '承运商' }},
        { label: '企业名称', prop: 'cpyName' },
        { label: '人员姓名', prop: 'cpyUserName' },
        // { label: '人员电话', prop: 'phone' },
        { label: '角色', prop: 'cpyUserPostName' },
        { label: '创建时间', prop: 'createTime', type: 'filterTime', width: '160px' },
      ],
      tableData: [],
      pagination: {
        total: 0,
        size: 10,
        page: 1,
      },
    };
  },

  computed: {},

  watch: {
    boudnEnterpriseVisible: {
      handler: function(visible) {
        if (visible) {
          this.getList();
        }
      },
      immediate: true,
    },
  },

  created() {},

  mounted() {},

  methods: {
    getList() {
      this.loading = true;
      const params = {
        id: this.boudnEnterpriseId,
        size: this.pagination.size,
        current: this.pagination.page,
      };
      companyUserRellist(params).then(res => {
        this.tableData = res?.records || [];
        this.pagination = {
          page: res?.current || 1,
          size: res?.size || 10,
          total: res?.total || 0,
        };
      }).finally(() => {
        this.loading = false;
      });
    },
    sizeChange(size) {
      this.pagination.page = 1;
      this.pagination.size = size;
      this.getList();
    },
    pageChange(page) {
      this.pagination.page = page;
      this.getList();
    },
    close() {
      this.$emit('update:boudnEnterpriseVisible', false);
    },
  },
};

</script>
<style lang='scss' scoped>
</style>

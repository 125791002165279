<template>
  <div class="left-panel-wrap">
    <el-tree
      ref="tree"
      v-loading="loading"
      class="company-wrap"
      :data="treeData"
      node-key="id"
      default-expand-all
      :expand-on-click-node="false"
      :current-node-key="parentId"
      highlight-current
      :props="defaultProps"
      @node-click="handleNodeClick"
    >
      <div slot-scope="{ node }" class="custom-tree-node">
        <TextToolTip :class="node.id===1?'parent-wrap':'children-wrap'" :text="node.label" />
      </div>
    </el-tree>
    <div class="fixed-toggle">
      <div class="arrow" :class="isFold ? 'arrow-right' : 'arrow-left'" @click="foldClick">
        <i :class="['iconfont',isFold ? 'icon-menu-unfold-line' : 'icon-menu-fold-line']" />
      </div>
    </div>
  </div>
</template>

<script>
import TextToolTip from '@/components/TextToolTip';
import { getSysTemDepartList } from '@/api/systemUser';
export default {
  components: { TextToolTip },
  props: {
    // 是否是收起状态
    isFold: {
      type: Boolean,
      default() {
        return false;
      },
    },
  },
  data() {
    return {
      loading: false,
      parentId: 1,
      treeData: [],
      defaultProps: {
        children: 'children',
        label: 'name',
      },
      clickData: null,
    };
  },

  created() {
    this.getTree();
  },
  methods: {
    buildTree(arr, parentId) {
      const result = [];
      for (const item of arr) {
        if (item.parentId === parentId) {
          const children = this.buildTree(arr, item.id);
          if (children.length) {
            item.children = children;
          }
          result.push(item);
        }
      }
      return result;
    },
    // 获取组织树
    getTree() {
      this.loading = true;
      getSysTemDepartList().then(res => {
        this.treeData = this.buildTree(res, 0);
      }).finally(() => {
        this.loading = false;
        if (!this.clickData) {
          this.clickData = this.treeData[0] || {};
        }
        this.parentId = this.clickData.id;
        this.$emit('treeClick', this.clickData || {});
      });
    },
    renderContent(h, { node, data, store }) {
      if (data.parentId === 0) {
        return <span style='color:#04122B; font-size: 16px; font-weight: 500;'>{node.label}</span>;
      } else {
        return <span style='color:#5A6073; font-size: 14px; font-weight: 400;'>{node.label}</span>;
      }
    },
    // 点击树
    handleNodeClick(data, fuData) {
      this.clickData = data;
      this.$emit('treeClick', data);
    },
    foldClick() {
      this.$emit('update:isFold', !this.isFold);
    },
  },
};

</script>
<style lang='scss' scoped>
.left-panel-wrap{
  position: relative;
  background: #fff;
  padding: 24px 30px 15px 15px;
  border-right: 1px solid #EDF0F5;
  .company-wrap{
    height: calc(100% - 100px);
    overflow-y: scroll;
    margin-top: 24px;
    padding-right: 24px;
    margin-right: -30px;
    // .company-item{
    //   padding: 15px 0 15px 15px;
    //   cursor: pointer;
    // }
    // .active{
    //   background: #DFEAFF;
    //   border-radius: 4px;
    // }
    ::v-deep{
      .custom-tree-node{
        width: 75%;
        .children-wrap{
          font-size: 15px;
        }
      }
    }
  }
  .fixed-toggle{
    .arrow {
      position: absolute;
      width: 20px;
      padding:8px 10px;
      background: #ffffff;
      cursor: pointer;
      z-index: 9;
      top:24px;
      margin: auto;
      transform: translateZ(100px);
      display: flex;
      align-items: center;
      justify-content: center;
      i {
        color: #5a6073;
      }
      span{
        display: inline-block;
        font-size: 12px;
        margin-top: 4px;
        line-height: 17px;
      }
    }
    .arrow-left{
      text-align: center;
      right: -1px;
      box-shadow: -2px 0 4px 0 rgb(0 0 0 / 10%);
      border-radius: 4px 0 0 4px;
      height: 36px;
      display: flex;
      align-items: center;
    }
    .arrow-right {
      width: 20px;
      right: -20px;
      box-shadow: 4px 0 4px 0 rgb(0 0 0 / 10%);
      border-radius: 0 4px 4px 0;
      // color: #006CFF;
      // i {
      //   color: #006CFF;
      // }
    }
  }
  .action-btn{
    width: 100%;
  }
}
</style>

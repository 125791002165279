<template>
  <div class="basic-data-wrap flex">
    <leftPanel
      ref="leftPanel"
      :class="['left-wrap',isFold?'left-half':'left-all']"
      :is-fold.sync="isFold"
      @treeClick="treeClick"
      @drawerSubmit="drawerSubmit"
    />
    <div :class="['right-wrap g-search-form-table',isFold?'right-half':'right-all']">
      <search-form id="search-form" ref="form" :model="form" :count="2" label-width="auto" :collapse="false" @search="searchHandle">
        <search-item label="" prop="name">
          <el-input v-model.trim="form.name" clearable placeholder="姓名" />
        </search-item>
        <search-item label="" prop="phone">
          <el-input v-model.trim="form.phone" clearable placeholder="手机号" />
        </search-item>
      </search-form>
      <Table
        :loading="loading"
        :table-height="tableHeight"
        :table-cols="tableCols"
        :table-data="tableData"
        :table-btns="tableBtns"
        :pagination="pagination"
        @selectTime="selectTime"
        @sizeChange="sizeChange"
        @pageChange="pageChange"
      >
        <template v-slot="{ col, row }">
          <div v-if="col.prop === 'state'" class="main-c-cursor">
            <el-switch :value="row.state" class="switchStyle" :active-value="1" active-color="#30B08F" inactive-color="#C0C4CC" :inactive-value="0" active-text="启用" inactive-text="禁用" @change="accountAction(row)" />
          </div>
        </template>
      </Table>
      <Drawer :drawer-visible.sync="drawerVisible" :drawer-type="drawerType" :drawer-id="id" :depart-ment="departMent" @drawerSubmit="drawerSubmit" />
      <BoudnEnterpriseDialog :boudn-enterprise-visible.sync="boudnEnterpriseVisible" :boudn-enterprise-id="boudnEnterpriseId" />
    </div>
  </div>
</template>

<script>
import leftPanel from './leftPanel';
import Drawer from './drawer';
import BoudnEnterpriseDialog from './boudnEnterpriseDialog';
import { getSysTemUserList, updateSysTemUser } from '@/api/systemUser';
// import { accountEnable, accountDisable } from '@/api/business/personnel';
export default {
  name: 'SystemUser',
  components: { leftPanel, Drawer, BoudnEnterpriseDialog },
  data() {
    return {
      loading: false,
      isFold: false,
      form: {},
      tableBtns: [
        {
          label: '新增用户',
          icon: 'el-icon-circle-plus-outline',
          evt: () => {
            this.openDialog('add');
          },
        },
      ],
      tableCols: [
        { label: '编号 ID', prop: 'userId', fixed: 'left', type: 'jump', evt: (data) => { this.openDialog('detail', data); } },
        { label: '姓名', prop: 'name' },
        { label: '手机号', prop: 'phone' },
        { label: '所属部门', prop: 'departListDesc' },
        // , type: 'transition', rulesObj: { PT004: '货客服', PT005: '车客服', PT006: '运营', PT007: '财务' }
        { label: '所属角色', prop: 'postListDesc' },
        // 数量，可点击查看该用户绑定的其他企业员工
        { label: '绑定企业', prop: 'myCompanyTotal', type: 'jump', evt: (data) => { this.boudnEnterpriseClick(data); } },
        { label: '创建时间', prop: 'createTime', sortable: 'custom', headTimeSelect: true, width: 160, type: 'filterTime' },
        { label: '账号状态', prop: 'state', sortable: 'custom', type: 'customize', width: 160 },
        { label: '操作', type: 'operation', fixed: 'right',
          btns: [
            {
              label: '编辑',
              evt: (data) => {
                this.openDialog('edit', data);
              },
            },
          ],
        },
      ],
      tableData: [],
      pagination: {
        page: 1,
        size: 10,
        total: 0,
      },
      id: null,
      phone: '',
      drawerType: 'add',
      drawerVisible: false,
      boudnEnterpriseVisible: false,
      boudnEnterpriseId: null,
      companyId: null,
      code: null,
      tableHeight: 'auto',
      departMent: {}, // 侧边所点击的部门信息
    };
  },
  mounted() {
    this.$nextTick(() => {
      this.handleResize();
    });
    window.addEventListener('resize', this.handleResize, true);
  },
  deactivated() {
    window.removeEventListener('resize', this.handleResize, true);
  },
  methods: {
    handleResize() {
      const screenHeight = document.documentElement.clientHeight;
      this.formHeight = document.getElementById('search-form').clientHeight;
      const otherHeight = this.formHeight + 48 + 82 + 20 + 56 + 52 + 20;
      this.tableHeight = screenHeight - otherHeight + 'px';
    },
    getList() {
      this.loading = true;
      const params = {
        ...this.form,
        size: this.pagination.size,
        current: this.pagination.page,
      };
      getSysTemUserList(params).then(res => {
        let records;
        if (res?.records) {
          records = res.records.map(d => {
            return {
              ...d,
              departListDesc: d.departList ? d.departList.map(d => d.name).join(',') : '',
              postListDesc: d.postList ? d.postList.map(d => d.postName).join(',') : '',
            };
          });
        } else {
          records = [];
        }

        this.tableData = records || [];
        this.pagination = {
          page: res?.current || 1,
          size: res?.size || 10,
          total: res?.total || 0,
        };
      }).finally(() => {
        this.loading = false;
      });
    },
    // 账号启用与禁用
    accountAction(data) {
      const status = +data.state;
      // 是否启用（1-是 2否）
      this.$confirm(`是否确定${status === 2 ? '启用' : '禁用'}此账号?`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(() => { // 确认操作
        // 关闭 or 开启
        const newData = JSON.parse(JSON.stringify(data));

        const params = {
          user: {
            // ...newData,
            name: newData.name,
            phone: newData.phone,
            remark: newData.remark,
            userId: newData.userId,
            updateUsername: newData.updateUsername,
            departId: data.departList ? data.departList.map(d => d.id)[0] : '',
            postCode: data.postList ? data.postList.map(d => d.postCode)[0] : '',
          },
          account: {
            account: newData.phone,
            state: +newData.state === 1 ? 2 : 1,
          },
        };
        updateSysTemUser(params).then(res => {
          this.$baseMessage(`${status === 2 ? '启用' : '禁用'}成功`, 'success');
          this.getList();
        });
      }).catch(() => { // 取消操作
        // this.getList();
      });
    },

    searchHandle() {
      this.getList();
    },
    sizeChange(size) {
      this.pagination.page = 1;
      this.pagination.size = size;
      this.getList();
    },
    pageChange(page) {
      this.pagination.page = page;
      this.getList();
    },
    openDialog(type, data) {
      if (type !== 'add') {
        this.id = data?.userId || null;
        this.phone = data?.phone || '';
      }
      this.drawerType = type;
      this.drawerVisible = true;
    },
    selectTime(time) {
      this.form.createTimeBegin = time ? time[0] : '';
      this.form.createTimeEnd = time ? time[1] : '';
      this.getList();
    },
    boudnEnterpriseClick(data) {
      this.boudnEnterpriseId = data.userId;
      this.boudnEnterpriseVisible = true;
    },
    treeClick(data) {
      // this.form.departId = data.id || '';
      this.departMent = data || {};
      this.getList();
    },
    drawerSubmit() {
      this.getList();
    },
  },
};

</script>
<style lang='scss' scoped>
@import "@/assets/scss/basicData.scss";
::v-deep{
    .switchStyle .el-switch__label {
      position: absolute;
      display: none;
      color: #fff;
    }
    .switchStyle .el-switch__label--left {
      z-index: 9;
      left: 18px;
    }
    .switchStyle .el-switch__label--right {
      z-index: 9;
      left: 0px;
    }
    .switchStyle .el-switch__label.is-active {
      display: block;
    }
    .switchStyle.el-switch .el-switch__core,
    .el-switch .el-switch__label {
      width: 60px !important;
    }
  }
</style>
